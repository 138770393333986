import { customerAlias } from "@/utils/customerAlias";

export default [

  { value: `{nome_${customerAlias().toLowerCase()}}`, text: `Nome do ${customerAlias()}` },

  { value: `{cpf_${customerAlias().toLowerCase()}}`, text: `CPF do ${customerAlias()}` },


  { value: "{cpf_responsavel_financeiro}", text: "CPF do responsável financeiro" },

  { value: "{nome_responsavel_financeiro}", text: "Nome do responsável financeiro" },

  { value: "{whatsapp_responsavel_financeiro}", text: "Whatsapp do responsável financeiro" },

  { value: "{email_responsavel_financeiro}", text: "Email do responsável financeiro" },

  { value: "{rg_responsavel_financeiro}", text: "RG do responsável financeiro" },


  { value: "{meu_contato}", text: "Meu Contato" },

  { value: "{link_meu_contato}", text: "Link para as informações de contato do profissional" },

  { value: "{valor}", text: "Valor em números" },

  { value: "{valor_extenso}", text: "Valor escrito por extenso" },

  { value: "{nome_profissional}", text: "Nome do profissional Responsável" },

  { value: "{registro_do_conselho}", text: "Registro do conselho " },

  { value: "{numero_de_sessoes}", text: "Número de sessões agendadas" },

  { value: "{numero_de_sessoes_extenso}", text: "Número de sessões agendadas escrito por extenso" },

  { value: "{data_pagamento}", text: "Data de pagamento" },

  { value: "{descricao}", text: "Descrição do serviço prestado " },

  { value: "{data_de_hoje}", text: "Data atual" },

  { value: "{data_de_hoje_extenso}", text: "Data atual escrita por extenso" },

  { value: "{detalhes}", text: "Detalhes da transação ou lista de recebíveis" }


]