<template>
  <v-dialog
    v-model="dialog"
    width="800px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title primary-title> Assinatura digital </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn @click="clear()" text icon color="primary">
              <v-icon> mdi-close </v-icon>
            </v-btn>
            <v-btn @click="undo()" text icon color="primary">
              <v-icon> mdi-arrow-u-left-top </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-card outlined height="95%">
          <VueSignature ref="SignatureForm" :sigOption="option" />
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" @click="handleSave()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueSignature from "vue-signature";

export default {
  components: {
    VueSignature,
  },

  data: () => ({
    dialog: false,
    signature: null,

    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "transparent",
      border: "1px solid #000",
    },
  }),

  mounted() {
    // this.$refs.SignatureForm.clear();
  },

  methods: {
    open() {
      this.dialog = true;
    },

    handleSave() {
      var png = this.$refs.SignatureForm.save();

      var img = `<img   src="${png}" />`;

      this.$emit("input", img);
      this.clear();
      this.dialog = false;
    },

    clear() {
      this.$refs.SignatureForm.clear();
    },
    undo() {
      this.$refs.SignatureForm.undo();
    },
    addWaterMark() {
      this.$refs.SignatureForm.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    fromDataURL(url) {
      // var _this = this;
      // _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
  },
};
</script>

<style lang="sass" scoped>
.sig-canvas
  width: 100%
  height: 100%
  background-color: rgba(244,244,244,0)
</style>