import { customerAlias } from "@/utils/customerAlias";

export default [
  "shortcuts", // the default shortcuts tab
  {
    name: "custom1", // new tab called custom1
    title: "Tags de Formatação",
    items: [
      {
        type: "htmlpanel",
        html: `<table class='tox-dialog__table'>  <thead>    <tr>      <th>Tag</th>      <th>Descrição</th>    </tr>  </thead>  <tbody>   <tr>    <td>{nome_${customerAlias().toLowerCase()}}</td>    <td>Nome do  ${customerAlias()}</td>  </tr>  <tr>    <td>{cpf_${customerAlias().toLowerCase()}}</td>    <td>CPF do  ${customerAlias()}</td>  </tr>  <tr>    <td>{meu_contato}</td>    <td>Informações de contato do profissional</td>  </tr>  <tr>    <td>{link_meu_contato}</td>    <td>Link para as informações de contato do profissional</td>  </tr>  <tr>    <td>{valor}</td>    <td>Valor em números</td>  </tr>  <tr>    <td>{valor_extenso}</td>    <td>Valor escrito por extenso</td>  </tr>  <tr>    <td>{nome_profissional}</td>    <td>Nome do profissional</td>  </tr>  <tr>    <td>{registro_do_conselho}</td>    <td>Registro do conselho profissional do profissional</td>  </tr>  <tr>    <td>{numero_de_sessoes}</td>    <td>Número de sessões agendadas</td>  </tr>  <tr>    <td>{numero_de_sessoes_extenso}</td>    <td>Número de sessões agendadas escrito por extenso</td>  </tr>  <tr>    <td>{data_pagamento}</td>    <td>Data de pagamento</td>  </tr>  <tr>    <td>{descricao}</td>    <td>Descrição do serviço prestado</td>  </tr>  <tr>    <td>{data_de_hoje}</td>    <td>Data atual</td>  </tr>  <tr>    <td>{data_de_hoje_extenso}</td>    <td>Data atual escrita por extenso</td>  </tr>  </tbody></table>`,
      },
    ],
  },
]