export default [
  'preview',
  // 'export',
  //'powerpaste',
  'casechange',
  'importcss',
  'tinydrive',
  'searchreplace',
  'autolink',
  'autosave',
  'save',
  'directionality',
  'advcode',
  'visualblocks',
  'visualchars',
  'fullscreen',
  'image',
  'link',
  // 'math',
  'media',
  'mediaembed',
  'codesample',
  'table',
  'charmap',
  'pagebreak',
  'nonbreaking',
  'anchor',
  // 'tableofcontents',
  'insertdatetime',
  'advlist',
  'lists',
  'checklist',
  'wordcount',
  // 'tinymcespellchecker',
  // 'a11ychecker',
  'help',
  'formatpainter',
  'pageembed',
  'charmap',
  'mentions',
  'quickbars',
  'linkchecker',
  'emoticons',
  'advtable',
  // 'footnotes',
  // 'mergetags',
  // 'autocorrect',
  // 'typography',
  // 'advtemplate',
  "print",

]

